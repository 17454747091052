<!-- 订单详情 -->
<template>
    <div class="container">
        <titleText title="订单信息"/>
        <div class="flex">
            <div class="order-info-wrapper flex">
                <i class="el-icon-s-order order-icon"></i>
                <div>
                    <p class="order-status">{{statusText}}</p>
                    <p class="order-meta flex">
                        <span>订单编号：</span>
                        <span>{{order.ordersn}}</span>
                    </p>
                    <p class="order-meta flex">
                        <span>下单时间：</span>
                        <span>{{order.createTime}}</span>
                    </p>
                    <p class="order-meta flex">
                        <span>订单备注：</span>
                        <span>{{order.iscomment}}</span>
                    </p>
                </div>
            </div>
            <div class="address-info-wrapper">
                <template v-if="order.isKuaidi == 0">
                    <div class="flex">
                        <p class="label font-bold">自提点：</p>
                        <div class="flex-1">
                            <p>{{userInfo.tXiaoqu}}</p>
                            <p class="mt-5">{{userInfo.tProvince}} {{userInfo.tCity}} {{userInfo.tArea}}</p>
                            <p class="mt-5">{{userInfo.tShopname}}</p>
                        </div>
                    </div>
                </template>
                <template v-if="order.isKuaidi == 1">
                    <div class="flex">
                        <p class="label font-bold">收货人：</p>
                        <div class="flex-1">{{order.sname}}</div>
                    </div>
                    <div class="flex mt-10">
                        <p class="label font-bold">手机号：</p>
                        <div class="flex-1">{{order.mobile}}</div>
                    </div>
                    <div class="flex mt-10">
                        <p class="label font-bold">收货地址：</p>
                        <div class="flex-1">
                            <p>{{order.province}} {{order.city}} {{order.district}}</p>
                            <p class="mt-5">{{order.address}}</p>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <template v-if="order.status == 0">
            <p class="font-bold pt-10 mb-10 mt-20">选择支付方式</p>
            <div class="payment-type-wrapper flex">
                <div @click="payTypeChangeFn('wx')" class="payment-type wx flex align-c justify-c" :class="payType == 'wx' ? 'active' : ''">
                    <i></i>
                    <span>微信支付</span>
                </div>
                <div v-if="disabledYuPay" class="payment-type ye flex align-c justify-c disabled">
                    <i></i>
                    <span>余额支付（￥{{balance.toFixed(2)}}）</span>
                </div>
                <div v-else @click="payTypeChangeFn('yu')" class="payment-type ye flex align-c justify-c" :class="payType == 'yu' ? 'active' : ''">
                    <i></i>
                    <span>余额支付（￥{{balance.toFixed(2)}}）</span>
                </div>
            </div>
        </template>
        <div class="mt-30">
            <div class="th flex align-c">
                <div class="th-info">商品信息</div>
                <div class="th-price">单价</div>
                <div class="th-amount">数量</div>
                <div class="th-sum">操作</div>
            </div>
            <div class="good-list-wrapper mt-20">
                <div class="tr flex font-12 " v-for="(item, index) in goodList" :key="index">
                    <div class="tr-info flex">
                        <div class="good-image" @click="toGoodDetail(item.productId)">
                            <el-image :src="item.thumb || 'https://o.rjkj7.com/data/upload/xcximages/xiangdon/good_default.jpg'" lazy style="width: 80px; height: 80px;">
                                <img slot="placeholder" src="https://o.rjkj7.com/data/upload/xcximages/xiangdon/good_default.jpg" style="width: 80px; height: 80px;">
                            </el-image>
                        </div>
                        <div class="ml-20 good-info">
                            <p class="title" @click="toGoodDetail(item.productId)">{{item.title || item.productName}}</p>
                            <p class="color-666 mt-10">
                                规格：{{item.specTitle}}
                                <template v-if="item.unit">
                                    /{{item.unit}}
                                </template>
                                <template v-if="item.skuTitle || item.optionname">
                                    /{{item.skuTitle || item.optionname}}
                                </template>
                            </p>
                            <p class="iden mt-10" v-if="item.productType == 3">秒杀</p>
                        </div>
                    </div>
                    <div class="tr-price">
                        <template v-if="item.productType == 5">￥{{item.marketprice.toFixed(2)}}+{{item.dedcredit}}积分</template>
                        <template v-else>￥{{item.marketprice.toFixed(2)}}</template>
                    </div>
                    <div class="tr-amount">
                        <span>{{item.total}}</span>
                    </div>
                    <div class="tr-sum">
                        <span v-if="(userInfo.id == order.mid || userInfo.id == order.tMid) && (item.uncancelable != 1) && (item.status == 1)"
                            @click="singleCancel(item.id)">单品取消</span>
                    </div>
                </div>
            </div>
            <div class="flex justify-e">
                <div class="order-bottom">
                    <p class="flex justify-s-b align-c">
                        <span class="label">优惠券：</span>
                        <span class="color-orange">-￥{{Number(order.discountprice).toFixed(2)}}</span>
                    </p>
                    <p class="flex justify-s-b align-c mt-10">
                        <span class="label">需付款：</span>
                        <span v-if="deductPrice > 0" class="color-orange font-20">￥{{Number(payPrice).toFixed(2)}}+{{deductPrice}}积分</span>
                        <span v-else class="color-orange font-20">￥{{Number(payPrice).toFixed(2)}}</span>
                    </p>
                </div>
            </div>
            <template v-if="order.status != -1 && order.status != 6">
                <div  class="flex justify-e mt-20">
                    <el-button class="btn cancel-btn" v-if="order.status != 2 && order.status != 3" @click="cancelOrder">取消订单</el-button>
                    <el-button class="btn submit-btn" v-if="order.status == 0 && order.paytype != 6" :loading="loading" @click="onPayment">立即付款</el-button>
                </div>
            </template>
        </div>
        <wxPay :isWxPay="isWxPay" ref="wxpay" @visibleChange="isWxPay=$event" />
    </div>
</template>

<script>
import titleText from '@/components/titleText'
import wxPay from '@/components/wxPay'
const STATUS_TEXT = {
  '-1': '订单已取消',
  0: '待付款',
  1: '等待商家发货',
  2: '等待买家确认收货',
  25: '部分发货',
  3: '订单已确认收货，待评价',
  4: '待退款',
  5: '售后中',
  6: '已完成',
}

export default {
    name: 'OrderDetail',
    components: { 
        titleText,
        wxPay
    },

    data() {
        return {
            userInfo: {},
            STATUS_TEXT: STATUS_TEXT,
            orderId: this.$route.query.id || '',
            order: {},
            goodList: [],
            balance: 0,                 //余额
            points: 0,                  //积分
            deductPrice: 0,             //积分商品，所需积分
            productCount: 0,            //商品总数
            payPrice: 0,                //实付金额
            payType: 'wx',              //支付方式
            disabledYuPay: false,       //禁用余额支付
            statusText: '',
            loading: false,
            isWxPay: false,
        };
    },

    created() {
        this.userInfo = this.$store.state.userInfo
        this.getOrder()
    },

    methods: {
        getOrder() {
            this.$https.get(this.$api.orderInfo,{
                orderId: this.orderId,
                id: '',
                iskuaidi:'',
                shopId: '',
                number: Math.random()
            })
            .then((res) =>{
                this.order = res.data
                this.balance = res.balance || 0
                this.points = res.points || 0

                this.onDifferentiate(this.order.orderProducts)
                this.productCountFun()
                this.payPriceFun()
                this.statusTextFun()
                this.balanceDisableFun()
            })
        },

        //区分数据
        onDifferentiate (data) {
            let temp = []
            let deductPrice = 0
            data.forEach((goods) => {
                goods.dedcredit = parseInt(goods.dedcredit)
                if(goods.productType == 5 && goods.dedcredit > 0) {
                    deductPrice += goods.dedcredit * goods.total
                }
                temp.push(goods)
            })
            this.goodList = temp
            this.deductPrice = deductPrice
        },

        //商品总数
        productCountFun () {
            let total = 0
            this.order.orderProducts.forEach(item => {
                total += item.total
                this.productCount = total
            })
        },

        //实付金额
        payPriceFun () {
            let payPrice = 0
            if (this.order.status === 0) {
                payPrice = Math.max(this.order.orderprice + this.order.dispatchprice 
                                - this.order.discountprice - this.order.bargainprice, 0)
            } else {
                payPrice = this.order.payprice + this.order.dispatchprice
            }
            this.payPrice = payPrice
        },

        //状态
        statusTextFun () {
            this.statusText = this.STATUS_TEXT[this.order.status]
        },

        //校验余额
        balanceDisableFun () {
            if(this.balance < this.payPrice) {
                this.disabledYuPay = true
                this.payType = 'wx'
            } else {
                this.payType = 'yu'
                this.disabledYuPay = false
            }
        },

        //切换支付方式
        payTypeChangeFn(type) {
            if (this.payType != type)
                this.payType = type
        },

        //单品取消
        singleCancel(id){
            this.$confirm('确定取消该商品吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$https.post(this.$api.goodsCancel,{
                    goodsId: id,
                })
                .then((res) =>{
                    this.$message({
                        message: '取消商品成功',
                        type: 'success'
                    })
                    this.getOrder()
                })   
            }).catch(() => {})
        },

        //取消订单
        cancelOrder () {
            if (this.order.status === 1) { //订单已支付
                let cancelAble = true;
                for (let i = 0; i < this.order.orderProducts.length; i++) {
                    if (this.order.orderProducts[i].uncancelable === 1) {//订单里存在不支持无理由退单
                        cancelAble = false;
                        break;
                    }
                }
                if (!cancelAble) {
                    this.$msgbox.alert('有不支持无理由退单商品，无法取消订单', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    })
                    return;
                }
            }

            this.$confirm('确定取消该订单吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$https.post(this.$api.orderCancel,{
                    orderId: this.order.id,
                })
                .then((res) =>{
                    this.$message({
                        message: '取消订单成功',
                        type: 'success'
                    })
                    this.getOrder()
                })   
            }).catch(() => {})
        },

        //支付
        onPayment () {
            //余额支付
            if (this.payType == 'yu' || this.payPrice == 0) {
                this.$confirm(`余额支付：¥${this.payPrice.toFixed(2)}`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$https.post(this.$api.yupay,{
                        orderId: this.orderId,
                        shipcardRecordId: '',//充值卡id
                        couponId: this.order.trId,//优惠卷id
                        points: this.deductPrice || ''//积分抵扣
                    })
                    .then((res) =>{
                        this.loading = false
                        this.$router.push({
                            name: 'payResult',
                            query: {
                                orderId: this.orderId
                            }
                        })
                    })
                }).catch(() => {})
            }
            //微信支付
            else if (this.payType == 'wx') {
                this.loading = true
                this.$https.post(this.$api.apppay,{
                    payType: 1,
                    orderId: this.orderId,
                    couponId: this.order.trId,//优惠卷id
                    points: this.deductPrice || ''//积分抵扣
                })
                .then((res) =>{
                    let qrcode = res.data.package.replace('code_url=', '')
                    this.loading = false
                    this.isWxPay = true
                    this.$refs.wxpay.init(qrcode, this.orderId, this.order.trId, this.deductPrice)
                })
            }
        },

        //跳转商品详情
        toGoodDetail(id) {
            let { href } = this.$router.resolve({
                                name: "goodDetail",
                                query: {
                                    id: id
                                }
                            });
            window.open(href, '_blank')
        },
    }
}
</script>
<style lang='scss' scoped>
    .payment-type-wrapper{
        .payment-type{
            width: 220px;
            height: 68px;
            margin-right: 30px;
            border: 1px solid #dedede;
            border-radius: 5px;
            cursor: pointer;
            &.active{
                border-color: #ff0036;
                background-color: rgba(255, 0, 54, .05);
            }
            &.disabled{
                cursor: not-allowed;
                background-color: #dedede;
            }
            i{
                width: 40px;
                height: 40px;
                margin-right: 5px;
                background: url(../../assets/images/sprite.png) no-repeat;
            }
            &.wx{
                i{
                    background-position: -141px -112px; 
                }
            }
            &.ye{
                i{ 
                    background-position: -194px -112px;  
                }
            }
        }
    }
    .order-info-wrapper{
        width: 360px;
        .order-icon{
            width: 60px;
            height: 60px;
            flex-shrink: 0;
            margin-right: 10px;
            color: #fff;
            font-size: 40px;
            text-align: center;
            line-height: 60px;
            border-radius: 50%;
            background-color: var(--blue-color);
        }
        .order-status{
            color: var(--blue-color);
            font-size: 18px;
            font-weight: bold;
            padding-bottom: 5px;
        }
        .order-meta{
            margin-top: 7px;
            color: #666;
            font-size: 12px;
            span:first-child{
                flex-shrink: 0;
            }
        }
    }  
    .address-info-wrapper{
        width: 400px;
        margin-left: 20px;
        border-left: 1px solid #dedede;
        .label{
            width: 120px;
            flex-shrink: 0;
            padding-right: 12px;
            text-align: right;
        }
    }
    .good-list-wrapper{
        padding: 0 20px;
        border: 1px solid #DEDEDE;
    }
    .th{
        height: 36px;
        padding: 0 20px;
        text-align: center;
        border: 1px solid #DEDEDE;
        background-color: #f1f1f1;
        .th-info{
            flex: 0 0 580px;
        }
        .th-price{
            flex: 0 0 200px;
        }
        .th-amount{
            flex: 0 0 180px;
        }
        .th-sum{
            flex: 0 0 198px;
        }
    }
    .tr{ 
        padding: 15px 0;
        border-bottom: 1px solid #DEDEDE;
        &:last-child{
            border: none;
        }
        .tr-info{
            flex: 0 0 580px;
            .good-info{
                max-width: 400px;
                .iden{
                    display: inline-block;
                    padding: 2px 8px;
                    color: #FF0000;
                    border-radius: 3px;
                    text-align: center;
                    border: 1px solid #FF0000;
                }
            }
        }
        .tr-price{
            flex: 0 0 200px;
            text-align: center;
        }
        .tr-amount{
            flex: 0 0 180px;
            text-align: center;
        }
        .tr-sum{
            flex: 0 0 198px;
            text-align: center;
            > span{
                cursor: pointer;
                &:hover{
                    color: var(--blue-color);
                }
            }
        }
    }
    .order-bottom{
        width: 240px;
        margin-top: 30px;
    }
    .btn{
        height: 48px;
        padding: 12px 46px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        border: none;
        background-color: var(--orange-color);
        &.cancel-btn{
            color: #333;
            background-color: transparent;
            border: 1px solid #999;
        }
    }
</style>